import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "@/redux/store"
// import { fetchBaseQuery } from "@reduxjs/toolkit/query"
// import { tokenReceived, loggedOut } from "./authSlice"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"

// export const API_URL_DEV = "https://my.scroogefrog.com/api/"
// export const API_URL_DEV = "https://dev.adn.scroogefrog.com/api/dev"
export const API_URL_DEV = "https://api.adn.scroogefrog.com/api/v1"

// const baseQuery = fetchBaseQuery({ baseUrl: API_URL_DEV })

// const baseQueryWithReauth: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions)
//   if (result.error && result.error.status === 401) {
//     // try to get a new token
//     const refreshResult = await baseQuery("/refresh", api, extraOptions)
//     if (refreshResult.data) {
//       // store the new token
//       // api.dispatch(tokenReceived(refreshResult.data))
//       // retry the initial query
//       result = await baseQuery(args, api, extraOptions)
//     } else {
//       // api.dispatch(loggedOut())
//     }
//   }
//   return result
// }

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL_DEV, // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
    prepareHeaders: (headers, { getState }: any) => {
      // const token = getState().auth.token
      const token = localStorage.getItem("token")
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`)
        return headers
      }
    },
  }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
})
