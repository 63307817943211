import { Button, Form, Input, Typography, Select } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import toast from "react-hot-toast"
import { AuthLayout } from "@/components/AuthLayout/AuthLayout"
// import { useAuth } from "@/providers/auth"
import { useSigninUserMutation } from "@/redux/slices/authApi"
import { useEffect } from "react"

const { Text } = Typography

const Login = () => {
  // const { onLogin } = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [signinUser, { data, isLoading, error, isError, isSuccess }] =
    useSigninUserMutation()

  const onFinish = (values: any) => {
    signinUser({ login: values.login, password: values.password })
  }

  useEffect(() => {
    if (isError) {
      toast.error(t("LOGIN_ERROR"))
    }
    if (isSuccess) {
      toast.success(t("LOGIN_SUCCESS"))
      localStorage.setItem("token", data.data.token)
      localStorage.setItem("role", "publisher")
      navigate("/publisher/statistics")
      // localStorage.setItem("role", data.data.role)
      // navigate(
      //   role === "publisher"
      //     ? "/publisher/statistics"
      //     : "/advertiser/statistics",
      // )
    }
  }, [isSuccess, isError, error])

  // console.log("RERENDER")

  return (
    <AuthLayout>
      <Select
        defaultValue={localStorage.getItem("lang") || "en"}
        style={{ width: 80, position: "absolute", top: 10, right: 10 }}
        onChange={(value) => {
          i18n.changeLanguage(value)
          localStorage.setItem("lang", value)
        }}
      >
        <Select.Option value="en">En</Select.Option>
        <Select.Option value="es">Es</Select.Option>
      </Select>

      <Form
        name="login"
        layout={"vertical"}
        // labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{
          maxWidth: 400,
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "80px auto",
        }}
      >
        <Form.Item
          label={t("LOGIN")}
          name="login"
          rules={[{ required: true, message: t("LOGIN_REQUIRED") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("PASSWORD")}
          name="password"
          rules={[{ required: true, message: t("PASSWORD_REQUIRED") }]}
        >
          <Input.Password />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 30,
          }}
        >
          <Text>{t("DONT_HAVE_ACCOUNT")}</Text>

          <Link to="/register" style={{ marginLeft: 10, color: "#00b96b" }}>
            {t("REGISTER_NOW")}
          </Link>
        </div>
        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit">
            {t("LOGIN")}
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default Login
