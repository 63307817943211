import { Divider, Typography, Spin, Table } from "antd"
import { useGetPubPaymentsQuery } from "@/redux/slices/publisherApi"

import { useTranslation } from "react-i18next"

const Payment = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetPubPaymentsQuery({})

  if (isLoading) return <Spin />

  return (
    <div>
      <Typography.Title level={3}>{t("PAYMENTS")}</Typography.Title>
      <Divider />
      <Table
        scroll={{ x: "max-content" }}
        dataSource={data}
        rowKey="id"
        columns={[
          {
            title: "№",
            dataIndex: "id",
            key: "id",
          },
          {
            title: t("DATE_OF_PAYMENT"),
            dataIndex: "start_date",
            key: "start_date",
          },
          {
            title: t("PAYMENT_AMOUNT") + " (USD)",
            dataIndex: "payment_amount",
            key: "payment_amount",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />
    </div>
  )
}

export default Payment
