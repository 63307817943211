import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Utils"],
})

export const utilsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentModels: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/PaymentModels/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Utils" }],
    }),
    getPaymentTypes: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/PaymentTypes/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Utils" }],
    }),
    getCountries: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Countries/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Utils" }],
    }),
    getTimezones: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/TimeZones/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Utils" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPaymentModelsQuery,
  useGetPaymentTypesQuery,
  useGetCountriesQuery,
  useGetTimezonesQuery,
} = utilsApi
