import { useState } from "react"
import { Button, Form, Input, Typography, Select, Spin, Checkbox } from "antd"
import { useSignUpUserMutation } from "@/redux/slices/authApi"
import toast from "react-hot-toast"
// import { setUser } from "@/redux/slices/authSlice"
import { useNavigate, Link, NavLink } from "react-router-dom"
import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"
import { useTranslation } from "react-i18next"
import { AuthLayout } from "@/components/AuthLayout/AuthLayout"

const { Text } = Typography

type ROLE = "publisher" | "advertiser"

const filterOption = (
  input: string,
  option?: { label: string; value: string },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

const Register = () => {
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [agree, setAgree] = useState(false)
  const [role, setRole] = useState<ROLE>("publisher")

  const [signinUser, { data, isLoading, error, isError, isSuccess }] =
    useSignUpUserMutation()

  const { data: timezonesData, isLoading: timezonesIsLoading } =
    useGetTimezonesQuery({})

  const onTimezoneChange = (value: any) => {
    form.setFieldsValue({ timezone: value })
  }

  const onFinish = (values: any) => {
    signinUser({
      login: values.email,
      password: values.password,
      conf_password: values.passwordConfirm,
      name: values.name,
      phone: values.phone,
      type: 1,
      timezone: values.timezone,
      role: role,
    })
  }

  if (isError) {
    toast.error(t("REGISTER_ERROR"))
    console.log("error", error)
    toast.error(JSON.stringify(data?.state?.errors))
  }
  if (isSuccess) {
    toast.success(t("REGISTER_SUCCESS"))
    localStorage.setItem("token", data?.data?.token)
    localStorage.setItem("role", role)
    navigate(
      role === "publisher" ? "/publisher/statistics" : "/advertiser/statistics",
    )
  }

  if (timezonesIsLoading) return <Spin size="large" />

  return (
    <AuthLayout>
      <Select
        defaultValue={localStorage.getItem("lang") || "en"}
        style={{ width: 80, position: "absolute", top: 10, right: 10 }}
        onChange={(value) => {
          i18n.changeLanguage(value)
          localStorage.setItem("lang", value)
        }}
      >
        <Select.Option value="en">En</Select.Option>
        <Select.Option value="es">Es</Select.Option>
      </Select>
      <Form
        layout={"vertical"}
        name="register"
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "10px auto",
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t("EMAIL")}
          name="email"
          rules={[{ required: true, message: t("EMAIL_REQUIRED") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("PASSWORD")}
          name="password"
          rules={[{ required: true, message: t("PASSWORD_REQUIRED") }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t("CONFIRM_PASSWORD")}
          name="passwordConfirm"
          rules={[{ required: true, message: t("PASSWORD_REQUIRED") }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t("NAME")}
          name="name"
          rules={[{ required: true, message: t("NAME_REQUIRED") }]}
        >
          <Input />
        </Form.Item>
        {role === "publisher" && (
          <Form.Item
            label={t("PHONE")}
            name="phone"
            rules={[{ required: true, message: t("PHONE_REQUIRED") }]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label={t("TIMEZONE")}
          name="timezone"
          rules={[{ required: true, message: t("TIMEZONE_REQUIRED") }]}
        >
          <Select
            optionFilterProp="children"
            filterOption={filterOption}
            onChange={onTimezoneChange}
            loading={timezonesIsLoading}
            showSearch
            options={timezonesData.map((timezone: string) => ({
              label: timezone,
              value: timezone,
            }))}
          />
        </Form.Item>

        <Checkbox
          onChange={() => setAgree(!agree)}
          style={{
            display: "flex",
            marginBottom: 30,
          }}
        >
          {t("AGREE")}{" "}
          <NavLink
            to={
              localStorage.getItem("lang") === "en"
                ? "https://docs.google.com/document/d/1qrWiiVOJ_hW_PY41GfpCmjD4Y6S4YsYtdSv84F3dc_E/edit?usp=sharing"
                : "https://docs.google.com/document/d/15YWCcdmzYlN924vxft88ALcnQFkEB3D1WLdcMFGXDac/edit#heading=h.vbebzk7l5p54"
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00b96b" }}
          >
            {t("PRIVACY")}
          </NavLink>
        </Checkbox>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
          }}
        >
          <Text>{t("HAVE_ACCOUNT")}</Text>
          <Link to="/login" style={{ marginLeft: 10, color: "#00b96b" }}>
            {t("LOGIN")}
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 30,
            width: 350,
          }}
        >
          {role === "publisher" ? (
            <>
              <Text>{t("NOT A PUBLISHER")}</Text>
              <div
                onClick={() => setRole("advertiser")}
                style={{
                  width: 200,
                  color: "#00b96b",
                  cursor: "pointer",
                  marginLeft: 10,
                }}
              >
                {t("SIGN_UP_AS_ADVERTISER")}
              </div>
            </>
          ) : (
            <>
              <Text>{t("NOT_AN_ADVERTISIER")}</Text>
              <Text
                onClick={() => setRole("publisher")}
                style={{
                  width: 200,
                  color: "#00b96b",
                  cursor: "pointer",
                  marginLeft: 10,
                }}
              >
                {t("SIGN_UP_AS_PUBLISHER")}
              </Text>
            </>
          )}
        </div>
        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit" disabled={!agree}>
            {t("SIGNUP")}
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default Register
