import React, { useEffect, useState } from "react"
import logo from "@/logo.svg"
import {
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  DesktopOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BugOutlined,
} from "@ant-design/icons"
import type { MenuProps } from "antd"
import {
  Layout,
  Menu,
  theme,
  Avatar,
  ConfigProvider,
  Typography,
  Button,
  Select,
  Space,
  Dropdown,
  Popconfirm,
  Spin,
  Modal,
} from "antd"
import { DarkModeSwitch } from "react-toggle-dark-mode"
import { useNavigate, NavLink, Outlet, useLocation } from "react-router-dom"
import styles from "./Layout.module.css"
import { useTranslation } from "react-i18next"
import { useGetUserInfoQuery } from "@/redux/slices/publisherApi"
import useThemeDetector from "@/hooks/useThemeDetector"
import { getLocaleOrSetDefault } from "@/utils"

const { Content, Sider, Header, Footer } = Layout

// import locale from "antd/locale/es_ES"
// import dayjs from "dayjs"

// import "dayjs/locale/es-es"

// const advSidebarMenu: MenuProps["items"] = [
//   {
//     key: "account",
//     label: <NavLink to="/advertiser/account">Account</NavLink>,
//     icon: <UserOutlined />,
//     // children: [
//     //   {
//     //     key: "statistics2",
//     //     label: <NavLink to="/advertiser/statistics">Sub</NavLink>,
//     //     icon: <TeamOutlined />,
//     //   },
//     // ],
//   },
//   {
//     key: "statistics",
//     label: (
//       <NavLink to="/advertiser/statistics" style={{ color: "inherit" }}>
//         Statistics
//       </NavLink>
//     ),
//     icon: <BarChartOutlined />,
//   },
//   {
//     key: "campaigns",
//     label: <NavLink to="/advertiser/campaigns">Campaigns</NavLink>,
//     icon: <ProfileOutlined />,
//   },
//   {
//     key: "moderation",
//     label: "Moderation",
//     // label: (
//     //   <NavLink to="/advertiser/moderation/offers" style={{ color: "inherit" }}>
//     //     Moderation
//     //   </NavLink>
//     // ),
//     // icon: <DesktopOutlined />,
//     icon: (
//       <Badge
//         count={2}
//         style={{
//           padding: "3px",
//           position: "absolute",
//           left: "95px",
//           top: "5px",
//         }}
//       >
//         <InfoCircleOutlined />
//       </Badge>
//     ),
//     children: [
//       {
//         key: "offers",
//         label: <NavLink to="/advertiser/moderation/offers">Offers</NavLink>,
//         icon: <FileOutlined />,
//       },
//       {
//         key: "creatives",
//         label: (
//           <NavLink to="/advertiser/moderation/creatives">Creatives</NavLink>
//         ),
//         icon: <FileImageOutlined />,
//       },
//       {
//         key: "texts",
//         label: <NavLink to="/advertiser/moderation/texts">Texts</NavLink>,
//         icon: <FileTextOutlined />,
//       },
//     ],
//   },
//   {
//     key: "graphs",
//     label: <NavLink to="/advertiser/graphs">Graph</NavLink>,
//     icon: <AreaChartOutlined />,
//   },
//   {
//     key: "payments",
//     label: <NavLink to="/advertiser/payments">Payments</NavLink>,
//     icon: <PayCircleOutlined />,
//   },
//   {
//     key: "tools",
//     label: "Tools",
//     icon: <ToolOutlined />,
//     children: [
//       {
//         key: "geolps",
//         label: (
//           <NavLink to="/advertiser/tools/geolps">Geolps statistics</NavLink>
//         ),
//         icon: <EnvironmentOutlined />,
//       },
//       {
//         key: "devices",
//         label: (
//           <NavLink to="/advertiser/tools/devices">Devices Statistics</NavLink>
//         ),
//         icon: <AndroidOutlined />,
//       },
//       {
//         key: "domains",
//         label: <NavLink to="/advertiser/tools/domains">Offers Domains</NavLink>,
//         icon: <BugOutlined />,
//       },
//     ],
//   },
// ]

// const getSidebarMenu = (role: string | null) => {
//   if (role === "advertiser") {
//     return advSidebarMenu
//   } else {
//     return [
//       {
//         key: "account",
//         // label: <NavLink to="/advertiser/account">Account</NavLink>,
//         label: <NavLink to={`/${role}/account`}>Account</NavLink>,
//         icon: <FileOutlined />,

//       },
//       {
//         key: "statistics",
//         label: (
//           <NavLink to={`/${role}/statistics`} style={{ color: "inherit" }}>
//             Statistics
//           </NavLink>
//         ),
//         icon: <TeamOutlined />,
//       },
//       {
//         key: "graphs",
//         label: <NavLink to={`/${role}/graphs`}>Graph</NavLink>,
//         icon: <DesktopOutlined />,
//       },
//       {
//         key: "offers",
//         label: <NavLink to={`/${role}/offers`}>Offers Domains</NavLink>,
//         icon: <BugOutlined />,
//       },
//       {
//         key: "payments",
//         label: <NavLink to={`/${role}/payments`}>Payments</NavLink>,
//         icon: <DesktopOutlined />,
//       },
//     ]
//   }
// }

interface CustomLayoutProps {
  children: React.ReactNode
}

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const role = "publisher"
  const location = useLocation()

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [collapsed, setCollapsed] = useState(true)

  const [collapsedWidth, setCollapsedWidth] = useState(isMobile ? "0" : "50")

  const getCurrent = () => {
    const path = location.pathname
    if (path.includes("account")) {
      return "account"
    } else if (path.includes("statistics")) {
      return "statistics"
    } else if (path.includes("campaigns")) {
      return "campaigns"
    } else if (path.includes("moderation")) {
      return "moderation"
    } else if (path.includes("graphs")) {
      return "graphs"
    } else if (path.includes("payments")) {
      return "payments"
    } else if (path.includes("geolps")) {
      return "geolps"
    } else if (path.includes("devices")) {
      return "devices"
    } else if (path.includes("offers")) {
      return "offers"
    }
  }

  const [current, setCurrent] = useState(getCurrent())

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (isMobile) {
      setCollapsed(true)
    }
    setCurrent(e.key)
  }

  const isDarkTheme = useThemeDetector()

  const [themeState, setTheme] = useState(
    localStorage.getItem("theme") || "dark",
  )

  useEffect(() => {
    const localTheme = localStorage.getItem("theme")
    localTheme
      ? setTheme(localTheme)
      : setTheme(isDarkTheme ? "dark" : "default")
  }, [])

  const toggleTheme = () => {
    const newTheme = themeState === "dark" ? "default" : "dark"
    localStorage.setItem("theme", newTheme)
    setTheme(newTheme)
  }

  const {
    data,
    isLoading,
    // isSuccess: isDataSuccess,
    isError,
  } = useGetUserInfoQuery({})

  const [logoutModalVisible, setLogoutModalVisible] = useState(false)

  const showLogoutModal = () => {
    setLogoutModalVisible(true)
  }

  if (isLoading) {
    return <Spin size="large" />
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Modal
        title={t("LOGOUT")}
        open={logoutModalVisible}
        onOk={() => {
          localStorage.removeItem("token")
          navigate("/login")
        }}
        width={300}
        onCancel={() => setLogoutModalVisible(false)}
        okButtonProps={{ danger: true }}
      />
      <Layout style={{ minHeight: "100vh" }}>
        <Header className={styles.header}>
          <Space>
            <Space>
              <Typography.Title level={5} style={{ color: "#fff" }}>
                <b>{data.balance}</b> USD
              </Typography.Title>
            </Space>
            <Select
              defaultValue={localStorage.getItem("lang") || "en"}
              style={{ width: 70 }}
              onChange={(value) => {
                i18n.changeLanguage(value)
                localStorage.setItem("lang", value)
              }}
            >
              <Select.Option value="en">En</Select.Option>
              <Select.Option value="es">Es</Select.Option>
            </Select>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "account",
                    label: (
                      <NavLink to="/publisher/account">{t("ACCOUNT")}</NavLink>
                    ),
                    icon: <UserOutlined />,
                    onClick: () => {
                      setCurrent("account")
                    },
                  },
                  {
                    key: "logout",
                    label: <div onClick={showLogoutModal}>{t("LOGOUT")}</div>,

                    icon: <LogoutOutlined />,
                  },
                ],
              }}
            >
              <Avatar size={"large"} icon={<UserOutlined />} />
            </Dropdown>
          </Space>
          <Space align={"center"} className={styles.headerLogo}>
            <NavLink
              to={
                role === "publisher"
                  ? "/publisher/account"
                  : "/advertiser/account"
              }
            >
              <img
                className={styles.logo}
                src={logo}
                style={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                }}
              />
            </NavLink>

            <div className={styles.logoWrapper}>
              <Typography.Title
                className={styles.logoText}
                level={5}
                style={{
                  color: "#fff",
                }}
              >
                ScroogeFrog
              </Typography.Title>
              <Typography.Title
                className={(styles.logoTextRight, styles.logoText)}
                level={5}
                style={{
                  color: "#fff",
                }}
              >
                AdNetwork
              </Typography.Title>
            </div>
            <Button
              className={styles.trigger}
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </Space>
        </Header>
        <Layout hasSider>
          <Sider
            theme="light"
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            breakpoint="lg"
            collapsedWidth={collapsedWidth}
            width={220}
            zeroWidthTriggerStyle={{
              display: "none",
            }}
            style={
              isMobile
                ? {
                    position: "absolute",
                    zIndex: 10,
                    height: "100vh",
                  }
                : {}
            }
            className={styles.sider}
            onBreakpoint={(broken) => {
              setCollapsedWidth(broken && isMobile ? "0" : "50")
            }}
          >
            <Space
              align="center"
              style={{ display: "flex", justifyContent: "center" }}
            ></Space>
            <Menu
              defaultSelectedKeys={["1"]}
              // @ts-ignore
              selectedKeys={[current]}
              mode="inline"
              items={[
                {
                  key: "account",
                  label: (
                    <NavLink to={`/${role}/account`}>{t("ACCOUNT")}</NavLink>
                  ),
                  icon: <FileOutlined />,
                },
                {
                  key: "statistics",
                  label: (
                    <NavLink
                      to={`/${role}/statistics`}
                      style={{ color: "inherit" }}
                    >
                      {t("STATISTICS")}
                    </NavLink>
                  ),
                  icon: <TeamOutlined />,
                },
                {
                  key: "graphs",
                  label: <NavLink to={`/${role}/graphs`}>{t("GRAPH")}</NavLink>,
                  icon: <DesktopOutlined />,
                },
                {
                  key: "offers",
                  label: (
                    <NavLink to={`/${role}/offers`}>{t("OFFERS")}</NavLink>
                  ),
                  icon: <BugOutlined />,
                },
                {
                  key: "payments",
                  label: (
                    <NavLink to={`/${role}/payments`}>{t("PAYMENTS")}</NavLink>
                  ),
                  icon: <DesktopOutlined />,
                },
              ]}
              onClick={handleMenuClick}
            />

            <div
              style={{
                display: "flex",
                margin: "20px auto",
                justifyContent: "center",
              }}
            >
              <DarkModeSwitch
                style={{ marginBottom: "2rem" }}
                checked={themeState === "dark" ? true : false}
                onChange={toggleTheme}
                size={25}
              />
            </div>
          </Sider>
          <Content style={{ margin: "20px 16px" }} className={styles.content}>
            {/* <Outlet /> */}
            {children}
          </Content>
        </Layout>
        <Footer className={styles.footer}>
          {t("RIGHTS")}
          <div style={{ display: "flex" }} className={styles.footerLinks}>
            <NavLink
              to={
                localStorage.getItem("lang") === "en"
                  ? "https://docs.google.com/document/d/1IQcjGB2M-jdhFMZ9Phb-0Wp2N66vvkIVDy6pZyvw2hI/edit#heading=h.u32pbek3punf"
                  : "https://docs.google.com/document/d/1G5zjNwQoAx3vCScAr9xCUokpdhhIarLXP0Wp7VTL2MM/edit#heading=h.u32pbek3punf"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("TERMS")}
            </NavLink>
            <NavLink
              to={
                localStorage.getItem("lang") === "en"
                  ? "https://docs.google.com/document/d/1qrWiiVOJ_hW_PY41GfpCmjD4Y6S4YsYtdSv84F3dc_E/edit?usp=sharing"
                  : "https://docs.google.com/document/d/15YWCcdmzYlN924vxft88ALcnQFkEB3D1WLdcMFGXDac/edit#heading=h.vbebzk7l5p54"
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "20px" }}
            >
              {t("PRIVACY")}
            </NavLink>
          </div>
        </Footer>
      </Layout>
    </Space>
  )
}

export default CustomLayout
