import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Publisher"],
})

export const publisherApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Publisher/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    updateUserInfo: builder.mutation<any, any>({
      query: ({ name, phone, timezone }) => ({
        url: `${createApiLink(`/Publisher/`)}`,
        method: "PUT",
        body: {
          name,
          phone,
          timezone,
        },
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Publisher" }],
    }),
    getPubCamps: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Publisher/Campaigns/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubSourc: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Publisher/Sources/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubPads: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Publisher/Pads/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubPayments: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Publisher/Payments/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubStats: builder.query<any, any>({
      query: ({ paramsString }) => ({
        url: `${createApiLink(`/Publisher/Statistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubGraphStats: builder.query<any, any>({
      query: ({ paramsString }) => ({
        url: `${createApiLink(`/Publisher/GraphStatistics/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
    getPubOffersStats: builder.query<any, any>({
      query: ({ paramsString }) => ({
        url: `${createApiLink(`/Publisher/OffersDomains/`, paramsString)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Publisher" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useGetPubCampsQuery,
  useGetPubSourcQuery,
  useGetPubPadsQuery,
  useGetPubPaymentsQuery,
  useLazyGetPubStatsQuery,
  useLazyGetPubGraphStatsQuery,
  useLazyGetPubOffersStatsQuery,
} = publisherApi
