import { useEffect, useState, useMemo } from "react"
import {
  Route,
  Routes,
  useNavigate,
  // useLocation,
  Navigate,
} from "react-router-dom"
import { Toaster } from "react-hot-toast"
import CustomLayout from "@/components/Layout"
import Login from "@/components/Login/Login"
import Register from "@/components/Register/Register"

import Account from "@/components/Account"
import Statistics from "@/components/Statistics"
import Payments from "@/components/Payment"
import Graph from "@/components/Graph"
import OfferDomains from "@/components/OfferDomains"

// import Advertiser from "@/components/Advertiser"
// import Publisher from "@/components/Publisher"
// import Campaigns from "@/components/Campaigns"
// import Moderation from "@/components/Moderation"
// import GeolpsStat from "@/components/GeolpsStat"
// import DevicesStat from "@/components/DevicesStat"
// import Support from "@/components/Support"
import { ConfigProvider, theme } from "antd"
import { getLocaleOrSetDefault } from "@/utils"
// import { AuthProvider } from "./providers/auth"
import { ProtectedRoute } from "@/components/ProtectedRoute"
// import { Providers } from "@/providers"
// import "./App.css"

export const AdvertiserRoutes = () => {
  return (
    <Routes>
      <Route path="account" element={<Account />} />
      <Route path="statistics" element={<Statistics />} />
      <Route path="payments" element={<Payments />} />
      <Route path="graphs" element={<Graph />} />
    </Routes>
  )
}

export const PublisherRoutes = () => {
  return (
    <Routes>
      <Route path="account" element={<Account />} />
      <Route path="statistics" element={<Statistics />} />
      <Route path="payments" element={<Payments />} />
      <Route path="graphs" element={<Graph />} />
      <Route path="offers" element={<OfferDomains />} />
    </Routes>
  )
}

function App() {
  const role = localStorage.getItem("role")
  const getRedirectLink = () => {
    if (role === "publisher") {
      return "/publisher/statistics"
    }
    if (role === "advertiser") {
      return "/advertiser/statistics"
    }
    return "/login"
  }

  return (
    // <AuthProvider>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#00b96b",
        },
      }}
      locale={getLocaleOrSetDefault()}
    >
      <Routes>
        <Route path="/" element={<Navigate replace to={getRedirectLink()} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/advertiser/*"
          element={
            <ProtectedRoute>
              <CustomLayout>
                <AdvertiserRoutes />
              </CustomLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/publisher/*"
          element={
            <ProtectedRoute>
              <CustomLayout>
                <PublisherRoutes />
              </CustomLayout>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={getRedirectLink()} replace />} />
      </Routes>

      <Toaster />
    </ConfigProvider>
    // </AuthProvider>
  )
}

export default App
