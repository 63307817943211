import { useState, useEffect } from "react"
import { Typography, Space, DatePicker, Table, Spin } from "antd"
import { useLazyGetPubStatsQuery } from "@/redux/slices/publisherApi"

import { useTranslation } from "react-i18next"

import dayjs from "dayjs"

const Statistics = () => {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState<any>(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState<any>(dayjs().format("YYYY-MM-DD"))

  const onChangeStartDate = (date: any, dateString: any) => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (date: any, dateString: any) => {
    setEndDate(dateString)
  }

  const [fetchData, { data, error, isLoading, isSuccess, isError }] =
    useLazyGetPubStatsQuery()

  useEffect(() => {
    fetchData({
      paramsString: `from=${startDate}&to=${endDate}`,
    })
  }, [startDate, endDate])

  if (isError) {
    return <div>error...</div>
  }

  if (isLoading) {
    return <Spin size="large" />
  }

  return (
    <div>
      <Space direction="vertical" size={"large"} style={{ maxWidth: "100%" }}>
        <Typography.Title level={3}>{t("STATISTICS")}</Typography.Title>

        <div>
          <DatePicker
            onChange={onChangeStartDate}
            placeholder={t("START_DATE")}
            defaultValue={dayjs().subtract(7, "day")}
            // locale={locale}
          />
          <DatePicker
            style={{ marginLeft: "20px" }}
            onChange={onChangeEndDate}
            placeholder={t("END_DATE")}
            defaultValue={dayjs()}
          />
        </div>

        <Typography.Title level={5}>
          {" "}
          {t("GENERAL_STATISTICS")}
          <br />
          <br />
        </Typography.Title>
      </Space>

      <Table
        loading={isLoading}
        scroll={{ x: "max-content" }}
        dataSource={data?.data}
        rowKey="id"
        columns={[
          {
            title: t("PAD"),
            dataIndex: "pad",
            key: "pad",
          },
          {
            title: t("SOURCE"),
            dataIndex: "source",
            key: "source",
          },

          {
            title: t("BLIND_VIEWS"),
            dataIndex: "blind_views",
            key: "blind_views",
          },
          {
            title: t("VIEWS"),
            dataIndex: "views",
            key: "views",
          },
          {
            title: t("BLOCK_VIEWS"),
            dataIndex: "block_views",
            key: "block_views",
          },
          {
            title: t("CLICKS"),
            dataIndex: "clicks",
            key: "clicks",
          },
          {
            title: "CTR (%)",
            dataIndex: "ctr",
            key: "ctr",
          },
          {
            title: "vCTR (%)",
            dataIndex: "vctr",
            key: "vctr",
          },
          {
            title: "CPM (USD)",
            dataIndex: "cpm",
            key: "cpm",
          },
          {
            title: "vCPM (USD)",
            dataIndex: "vcpm",
            key: "vcpm",
          },
          {
            title: t("PUBS_PROFIT") + " (USD)",
            dataIndex: "debit_cpc",
            key: "debit_cpc",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Statistics
