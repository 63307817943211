import {
  Breadcrumb,
  Divider,
  Typography,
  Spin,
  Button,
  Select,
  Space,
  Table,
  DatePicker,
  Checkbox,
} from "antd"
import { HomeOutlined } from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import { useState } from "react"
import {
  useGetPubCampsQuery,
  useLazyGetPubOffersStatsQuery,
} from "@/redux/slices/publisherApi"

import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const getValueByBoolean = (value: boolean) => (value ? 1 : 0)

const OfferDomains = () => {
  const { t } = useTranslation()
  const role = localStorage.getItem("role")
  const [campaign, setCampaign] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [geo, setGeo] = useState("")
  const [showUrlsStat, setShowUrlsStat] = useState(false)

  const onChangeStartDate = (date: any, dateString: any) => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (date: any, dateString: any) => {
    setEndDate(dateString)
  }

  const { data: pubCampsData, isLoading: pubCampsIsLoading } =
    useGetPubCampsQuery({})

  const [fetchData, { data, error, isLoading, isSuccess, isError }] =
    useLazyGetPubOffersStatsQuery()

  if (pubCampsIsLoading) {
    return <Spin size="large" />
  }

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography.Title level={4}>{t("OFFERS_DOMAINS")}</Typography.Title>
        {role === "advertiser" && (
          <>
            <Divider type="vertical" style={{ height: "1.3rem" }} />
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/${role}/statistics`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Tools
                {/* <NavLink to={`/${role}/moderation`}></NavLink> */}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Offers Domains
                {/* <NavLink to={`/${role}/moderation/${type}`}></NavLink> */}
              </Breadcrumb.Item>
            </Breadcrumb>{" "}
          </>
        )}
      </div>
      <br />
      <Space direction="vertical" size={"large"} style={{ maxWidth: "100%" }}>
        <Select
          placeholder={t("SELECT") + " " + t("CAMPAIGN")}
          style={{ width: 200 }}
          optionFilterProp="children"
          loading={pubCampsIsLoading}
          showSearch
          onChange={(value: any) => {
            setCampaign(value)
          }}
          options={pubCampsData.map((camp: any, key: number) => ({
            label: camp.name,
            value: camp.campaign_id,
          }))}
        />
        <div>
          <DatePicker
            onChange={onChangeStartDate}
            placeholder={t("START_DATE")}
            defaultValue={dayjs().subtract(7, "day")}
          />
          <DatePicker
            style={{ marginLeft: "20px" }}
            onChange={onChangeEndDate}
            placeholder={t("END_DATE")}
            defaultValue={dayjs()}
          />
        </div>
        {role !== "advertiser" && (
          <Select placeholder={t("ALL")}>
            <Select.Option value="1">{t("ALL")}</Select.Option>
            <Select.Option value="2">FR</Select.Option>
            <Select.Option value="3">US</Select.Option>
            <Select.Option value="4">CL</Select.Option>
          </Select>
        )}

        <Space direction="horizontal" size={"large"}>
          <Checkbox
            onChange={(e) => {
              setShowUrlsStat(e.target.checked)
            }}
          >
            {t("SHOW_ULRS_STAT")}
          </Checkbox>
        </Space>
        <Button
          type="primary"
          onClick={() => {
            fetchData({
              paramsString: `from=${startDate}&to=${endDate}&show_url_stat=${getValueByBoolean(
                showUrlsStat,
              )}&campaign_id=${campaign}`,
            })
          }}
        >
          {t("SHOW")}
        </Button>
      </Space>
      <br />
      <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        rowKey="id"
        dataSource={data?.domains}
        columns={[
          {
            title: t("FIRST_DATA"),
            dataIndex: "date",
            key: "date",
          },
          {
            title: t("OFFER"),
            dataIndex: "offer",
            key: "offer",
          },
          {
            title: t("COUNTRY"),
            dataIndex: "country",
            key: "country",
          },
          {
            title: t("DOMAIN"),
            dataIndex: "domain",
            key: "domain",
          },
          {
            title: t("BAD"),
            dataIndex: "bad",
            key: "bad",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />
    </div>
  )
}

export default OfferDomains
