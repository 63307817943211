import {
  Typography,
  Spin,
  Button,
  DatePicker,
  Select,
  Space,
  Checkbox,
} from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import {
  useGetPubPadsQuery,
  useLazyGetPubGraphStatsQuery,
} from "@/redux/slices/publisherApi"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

import { useTranslation } from "react-i18next"

type ChartItem = {
  date: string
  clicks?: number
  views?: number
  profit?: number
}

type ChartData = ChartItem[]

const getValueByBoolean = (value: boolean) => (value ? 1 : 0)

const GRAPHIC_DATA = [] as any
const startDate = new Date("2022-10-25")
const endDate = new Date("2022-12-31")

for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
  GRAPHIC_DATA.push({
    date: new Date(d).toISOString().split("T")[0],
    clicks: Math.floor(Math.random() * 10).toString(),
    views: Math.floor(Math.random() * 100).toString(),
    profit: (Math.random() * 10).toFixed(8),
  })
}

const filterEmpty = (data: ChartData) => {
  if (!data) {
    return []
  } else {
    return data.filter(
      (entry: ChartItem) =>
        entry.clicks !== 0 && entry.views !== 0 && entry.profit !== 0,
    )
  }
}

const Graph = () => {
  const { t } = useTranslation()
  const role = localStorage.getItem("role")

  const { data: pubSourcData, isLoading: pubSourcIsLoading } =
    useGetPubPadsQuery({})

  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  )
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"))
  const [isViews, setIsViews] = useState(true)
  const [isClicks, setIsClicks] = useState(false)
  const [isProfit, setIsProfit] = useState(false)
  const [pad, setPad] = useState("All")
  const [chartData, setChartData] = useState([] as ChartData)

  const onChangeStartDate = (date: any, dateString: any) => {
    setStartDate(dateString)
  }

  const onChangeEndDate = (date: any, dateString: any) => {
    setEndDate(dateString)
  }

  const [fetchData, { data, error, isLoading, isSuccess, isError }] =
    useLazyGetPubGraphStatsQuery()

  useEffect(() => {
    fetchData({
      paramsString: `from=${startDate}&to=${endDate}&views=${getValueByBoolean(
        isViews,
      )}&clicks=${getValueByBoolean(isClicks)}&profit=${getValueByBoolean(
        isProfit,
      )}&pad_id=${pad}`,
    })
  }, [startDate, endDate, isViews, isClicks, isProfit, pad])

  useEffect(() => {
    if (data) {
      // console.log(22222, filterEmpty(data || []))
      // setChartData(filterEmpty(data || []) as ChartData)
      // setChartData((GRAPHIC_DATA || []) as ChartData)
      // console.log(data)
      setChartData(data || ([] as ChartData))
    }
  }, [data])

  if (pubSourcIsLoading) {
    return <Spin size="large" />
  }

  const PAD_ALL = [
    {
      label: t("ALL"),
      value: "all",
    },
  ]

  return (
    <div>
      <Space direction="vertical" size={"large"}>
        <Typography.Title level={3}>{t("GRAPHS")}</Typography.Title>

        <div>
          <DatePicker
            onChange={onChangeStartDate}
            placeholder={t("START_DATE")}
            defaultValue={dayjs().subtract(7, "day")}
          />
          <DatePicker
            style={{ marginLeft: "20px" }}
            onChange={onChangeEndDate}
            placeholder={t("END_DATE")}
            defaultValue={dayjs()}
          />
        </div>

        {role === "advertiser" ? (
          <Space direction="horizontal" size={"large"}>
            <Checkbox>{t("VIEWS")}</Checkbox>
            <Checkbox>{t("CLICKS")}</Checkbox>
            <Checkbox>{t("COST")}</Checkbox>
          </Space>
        ) : (
          <Space direction="horizontal" size={"large"}>
            <Checkbox
              defaultChecked
              onClick={() => {
                setIsViews(!isViews)
              }}
            >
              {t("BLOCK_VIEWS")}
            </Checkbox>
            <Checkbox
              onClick={() => {
                setIsClicks(!isClicks)
              }}
            >
              {t("CLICKS")}
            </Checkbox>
            <Checkbox
              onClick={() => {
                setIsProfit(!isProfit)
              }}
            >
              {t("PROFIT")}
            </Checkbox>
          </Space>
        )}

        <Select
          style={{ width: 200 }}
          optionFilterProp="children"
          showSearch
          placeholder={t("ALL")}
          onChange={(value: any) => {
            setPad(value)
          }}
          options={PAD_ALL.concat(
            pubSourcData.map((pad: any) => ({
              label: pad.domain,
              value: pad.pad_id,
            })),
          )}
        />
      </Space>
      <br />
      <br />
      <br />
      <br />

      <div style={{ width: "100%", height: "300px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {isViews && (
              <Line
                type="monotone"
                name={t("BLOCK_VIEWS") as unknown as string}
                dataKey="views"
                stroke="#00b96b"
                activeDot={{ r: 8 }}
              />
            )}
            {isClicks && (
              <Line
                type="monotone"
                name={t("CLICKS") as unknown as string}
                dataKey="clicks"
                stroke="#1890ff"
                activeDot={{ r: 8 }}
              />
            )}
            {isProfit && (
              <Line
                type="monotone"
                name={t("PROFIT") as unknown as string}
                dataKey="profit"
                stroke="#ff4d4f"
                activeDot={{ r: 8 }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Graph
