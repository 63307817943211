import { Navigate, useNavigate, useLocation, Outlet } from "react-router-dom"
// import { useAuth } from "@/providers/auth"

export const ProtectedRoute = ({ children }: any) => {
  const location = useLocation()
  // const { token } = useAuth()
  const token = localStorage.getItem("token")

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
  // if (token) {
  //   return (
  //     <Navigate to="/publisher/statistics" replace state={{ from: location }} />
  //   )
  // }

  return children

  // If authenticated, render the child routes
  // return <Outlet />
}
