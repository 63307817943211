import { useEffect, useState } from "react"
import { Typography, Input, Button, Table } from "antd"
import { Select, Space, Spin } from "antd"
// import { timezones } from "@/utils/timezones"
import {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useGetPubCampsQuery,
  useGetPubSourcQuery,
} from "@/redux/slices/publisherApi"
import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"

import { useTranslation } from "react-i18next"

const filterOption = (
  input: string,
  option?: { label: string; value: string },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

const Account = () => {
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const {
    data,
    isLoading,
    // isSuccess: isDataSuccess,
    isError,
  } = useGetUserInfoQuery({})
  // const { data: timezonesData, isLoading: timezonesIsLoading } =
  //   useGetTimezonesQuery({})

  const [updateUserInfo, { isSuccess: isUserInfoUpdateSuccess }] =
    useUpdateUserInfoMutation()

  const { data: pubCampsData, isLoading: pubCampsIsLoading } =
    useGetPubCampsQuery({})

  const { data: pubSourcData, isLoading: pubSourcIsLoading } =
    useGetPubSourcQuery({})

  useEffect(() => {
    if (data) {
      // console.log("isSuccess")
      setName(data.name)
      setPhone(data.phone)
      // setTimezone(data.time_zone)
    }
  }, [data])

  const onNameOrPhoneChange = (e: any) => {
    if (e.target.placeholder === "Name") setName(e.target.value)
    else setPhone(e.target.value)
  }

  // const onTimezoneChange = (value: any) => {
  //   setTimezone(value)
  // }

  if (isLoading || pubCampsIsLoading || pubSourcIsLoading)
    return <Spin size="large" />

  return (
    <div>
      <Space direction="vertical" size={"large"} style={{ maxWidth: "100%" }}>
        <Typography.Title level={3}>
          {t("PUBLISHER")} {t("ACCOUNT")}
        </Typography.Title>
        <Typography.Text type="success">
          {t("LOGIN")}: {data.login}
        </Typography.Text>
        <Input
          size={"middle"}
          placeholder={t("NAME")}
          value={name}
          onChange={onNameOrPhoneChange}
        />
        <Input
          placeholder={t("PHONE")}
          value={phone}
          onChange={onNameOrPhoneChange}
        />
        <Select
          // placeholder="Timezone"
          // style={{ width: 200 }}
          // placeholder={t("TIMEZONE")}
          // optionFilterProp="children"
          // filterOption={filterOption}
          // onChange={onChange}
          // onSearch={onSearch}
          // loading={timezonesIsLoading}
          // showSearch
          // defaultValue={timezone}
          // onChange={onTimezoneChange}
          value={data.time_zone}
          disabled
          // options={timezonesData.map((timezone: string) => ({
          //   label: timezone,
          //   value: timezone,
          // }))}
        />
        <Button onClick={() => updateUserInfo({ name, phone })} type="primary">
          {t("SAVE")}
        </Button>
      </Space>

      <br></br>
      <br></br>

      <Typography.Title level={5}>
        {t("PUBLISHER")} {t("CAMPAIGNS")}
      </Typography.Title>
      <br></br>
      <Table
        scroll={{ x: "max-content" }}
        dataSource={pubCampsData}
        rowKey="campaign_id"
        columns={[
          {
            title: "ID",
            dataIndex: "campaign_id",
            key: "campaign_id",
            width: "5%",
          },
          {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />

      <Typography.Title level={5}>{t("SOURCES")}</Typography.Title>
      <br></br>
      <Table
        scroll={{ x: "max-content" }}
        dataSource={pubSourcData}
        rowKey="id"
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "5%",
          },
          {
            title: t("SOURCE"),
            dataIndex: "source",
            key: "source",
            width: "30%",
          },
          {
            title: t("PAD"),
            dataIndex: "pad",
            key: "pad",
          },

          {
            title: t("PAYMENT_MODEL"),
            dataIndex: "payment_model",
            key: "payment_model",
          },
          {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
          },
          {
            title: t("TEASER_TYPE"),
            dataIndex: "teaser_type",
            key: "teaser_type",
          },
        ]}
        pagination={{ position: ["bottomCenter"] }}
      />
    </div>
  )
}

export default Account
