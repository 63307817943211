import { useState } from "react"
import {
  Button,
  Form,
  Input,
  ConfigProvider,
  Layout,
  theme,
  Typography,
  Select,
  Spin,
  Checkbox,
} from "antd"
const { Header } = Layout
import logo from "@/logo.svg"
import { useSignUpUserMutation } from "@/redux/slices/authApi"
import { useAppDispatch } from "@/redux/hook"
import toast from "react-hot-toast"
// import { setUser } from "@/redux/slices/authSlice"
import { useNavigate, Link } from "react-router-dom"
import { useGetTimezonesQuery } from "@/redux/slices/utilsApi"
import { useTranslation } from "react-i18next"
import { getLocaleOrSetDefault } from "@/utils"

const { Text } = Typography

export const AuthLayout = ({ children }: any) => {
  const { t, i18n } = useTranslation()
  //   const navigate = useNavigate()

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#000",
          justifyContent: "center",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: "50px",
            height: "50px",
            marginRight: "10px",
          }}
        />
        <div>
          <Typography.Title
            level={5}
            style={{
              color: "#fff",
            }}
          >
            ScroogeFrog
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{
              color: "#fff",
            }}
          >
            AdNetwork
          </Typography.Title>
        </div>
      </Header>
      {children}
    </Layout>
  )
}
