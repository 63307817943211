import {
  Action,
  configureStore,
  ThunkAction,
  // createListenerMiddleware,
  // isRejectedWithValue,
} from "@reduxjs/toolkit"
// import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { authApi } from "@/redux/slices/authApi"
// import authReducer from "@/redux/slices/authSlice"
// import toast from "react-hot-toast"
// import * as Sentry from "@sentry/react"
import { publisherApi } from "@/redux/slices/publisherApi"

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options listed below
// })

// export const rtkQueryErrorLogger: Middleware =
//   (api: MiddlewareAPI) => (next) => (action) => {
//     // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
//     if (isRejectedWithValue(action)) {
//       console.warn(
//         "Async error! We got a rejected action!",
//         action.error.data.message,
//       )
//       toast.error("This didn't work.")
//     }

//     return next(action)
//   }

// Create the middleware instance and methods
// const listenerMiddleware = createListenerMiddleware()

// listenerMiddleware.startListening({
//   matcher: authApi.endpoints.login.matchFulfilled,
//   effect: async (action, listenerApi) => {
//     // console.log("LOGIN fulfilled", listenerApi, usersApi)
//     // listenerApi.dispatch(usersApi.endpoints.getUsers.initiate())
//   },
// })

// @ts-ignore
export const store = configureStore({
  reducer: {
    // auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [publisherApi.reducerPath]: publisherApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // .prepend(rtkQueryErrorLogger)
      // .prepend(listenerMiddleware.middleware)
      .concat(authApi.middleware)
      .concat(publisherApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
